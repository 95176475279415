import React from 'react';

import Table from '@/components/Table';
import Grid from '@/atoms/Grid';

import { getUri } from '@/utils/routing';
import theme from '@/styles/theme';

export default ({ projects, ...props }) => {
  const tableData = projects.map(project => ({
    key: project.key,
    link: getUri(project.slug.current, 'project'),
    columns: [
      {
        key: 'number',
        content: `NR${project.projectNumber}`,
        w: [4 / 12, 3 / 12],
        colorType: 'text',
      },
      {
        key: 'title',
        content: project.title,
        w: [7 / 12, 8 / 12],
        colorType: 'title',
      },
      {
        key: 'arrow',
        content: '→',
        w: [1 / 12],
        colorType: 'title',
        align: 'right',
      },
    ],
  }));

  return (
    <Grid.Container mb={theme.spacing.large} {...props}>
      <Table rows={tableData} colorScheme="black" />
    </Grid.Container>
  );
};
