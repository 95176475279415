import React, { useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import useMeasure from '@/hooks/useMeasure';

import { TransitionLink } from '@/components/PageTransition';
import ArrowButton from '@/atoms/ArrowButton';
import Box from '@/atoms/Box';
import Text from '@/atoms/Text';

import { getProjectOverviewUri } from '@/utils/routing';
import { media, scale } from '@/styles/utils';
import { fastTransition } from '@/styles/pageTransitions';
import theme from '@/styles/theme';

const ExpandableContent = styled(animated.div)`
  overflow: hidden;
  position: relative;

  ${media.lg`
    height: auto !important;
    opacity: 1 !important;
  `}
`;

const ListItem = styled(Text)`
  && a {
    ${props =>
      props.isActive &&
      `
      color: ${theme.colors.black};
    `}
  }
`;

const Label = styled(Text)`
  position: absolute;
  top: 0;
  left: ${scale(2)};
  transition: all 0.15s ease-in-out;
  border: 0;
  background: none;
  opacity: 0;
  pointer-events: none;

  ${props =>
    props.isActive &&
    `
    opacity: 1;
    pointer-events: all;
  `}

  ${media.lg`
    display: none;
  `}
`;

const Toggle = styled(ArrowButton)`
  z-index: 10;
  position: absolute;
  top: -${scale(0.625)};
  left: -${scale(0.5)};
  transform: scaleY(${props => (props.$isExpanded ? -1 : 1)});
  transform-origin: center center;
  transition: transform 0.1s ease-out;

  ${media.lg`
    display: none;
  `}
`;

const List = styled(Box).attrs({ as: 'ul' })`
  list-style: none;

  li {
    transition: all 0.15s ease-in-out;
  }
`;

export default ({
  categories,
  activeCategory = null,
  activeView,
  ...props
}) => {
  const [isExpanded, setExpaded] = useState(false);
  const [measureRef, { height }] = useMeasure();
  const springProps = useSpring({
    height: isExpanded ? height : 0,
    opacity: isExpanded ? 1 : 0,
  });

  const linkProps = {
    preventScrollUpdate: true,
    ...fastTransition,
  };

  const handleToggle = () => setExpaded(!isExpanded);

  const activeCategoryItem = categories.find(
    cat => cat.slug.current === activeCategory
  );

  const activeLabel = activeCategoryItem?.title || 'Featured';

  return (
    <Box $position="relative" pr={[1, 1, 1, 0]} {...props}>
      <Toggle
        onClick={handleToggle}
        variant="down"
        $isExpanded={isExpanded}
        $size={0.5}
        $color="black"
      />

      <Label as="button" isActive={!isExpanded} onClick={handleToggle}>
        {activeLabel}
      </Label>

      <Box $position="relative" pl={[2, 2, 2, 0]}>
        <ExpandableContent style={springProps}>
          <List ref={measureRef}>
            <ListItem as="li" isActive={activeCategory === null} $color="gray">
              <TransitionLink
                to={getProjectOverviewUri(null, activeView)}
                {...linkProps}
              >
                Featured
              </TransitionLink>
            </ListItem>

            {categories.map(({ slug, title }) => (
              <ListItem
                as="li"
                key={slug.current}
                isActive={slug.current === activeCategory}
                $color="gray"
              >
                <TransitionLink
                  to={getProjectOverviewUri(slug.current, activeView)}
                  {...linkProps}
                >
                  {title}
                </TransitionLink>
              </ListItem>
            ))}
          </List>
        </ExpandableContent>
      </Box>
    </Box>
  );
};
