/**
 * Convert project-tile data to single object and store category slugs in index
 * for easier filtering
 * */
export const normalizeProjects = projectTiles =>
  projectTiles.map(item => {
    const projectData = item.project || item;

    const categoryIndex = projectData.categories
      ? projectData.categories.map(category => category.slug.current)
      : [];

    return {
      ...item,
      ...projectData,
      categoryIndex,
    };
  });
