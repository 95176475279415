import React from 'react';
import styled from 'styled-components';

import { TransitionLink } from '@/components/PageTransition';
import Box from '@/atoms/Box';
import Text from '@/atoms/Text';

import { media } from '@/styles/utils';
import { getProjectOverviewUri } from '@/utils/routing';
import { fastTransition } from '@/styles/pageTransitions';
import theme from '@/styles/theme';

const ListItem = styled(Text)`
  && a {
    ${props =>
      props.isActive &&
      `
      color: ${theme.colors.black};
    `}
  }
`;

const List = styled(Box).attrs({ as: 'ul' })`
  list-style: none;
  text-align: right;

  li {
    display: inline;
    transition: all 0.15s ease-in-out;
  }

  ${media.lg`
    text-align: left;
  `}
`;

export default ({ activeCategory, activeView = null, ...props }) => {
  const linkProps = {
    preventScrollUpdate: true,
    ...fastTransition,
  };

  return (
    <List {...props}>
      <ListItem as="li" isActive={activeView !== 'list'} $color="gray">
        <TransitionLink
          to={getProjectOverviewUri(activeCategory, null)}
          {...linkProps}
        >
          Grid
        </TransitionLink>
      </ListItem>

      <Box $display="inline" $color="gray">
        {' '}
        /{' '}
      </Box>

      <ListItem as="li" isActive={activeView === 'list'} $color="gray">
        <TransitionLink
          to={getProjectOverviewUri(activeCategory, 'list')}
          {...linkProps}
        >
          List
        </TransitionLink>
      </ListItem>
    </List>
  );
};
