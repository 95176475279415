import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import Cookies from 'js-cookie';

import Heading from '@/atoms/Heading';
import Head from '@/components/Head';
import Footer from '@/components/Footer';
import PageContainer from '@/components/PageContainer';
import ModulesLoop from '@/components/ModulesLoop';
import ProjectGrid from '@/components/ProjectGrid';
import ProjectTable from '@/components/ProjectTable';
import ProjectOverviewCategories from '@/components/ProjectOverviewCategories';

import { normalizeProjects } from '@/utils/normalization';
import { mapEdgesToNodes, mapToSingleObject, shuffle } from '@/utils/helpers';
import { getQueryParams } from '@/utils/routing';

export default ({
  data: {
    page: { title, meta, contentModules },
    projects,
    overview,
  },
  location,
}) => {
  // Cookie is set on each request via Netlify Edge function
  // region can be SCAND or WORLD
  const region = Cookies.get('region');

  const {
    featuredProjectsWorld,
    featuredProjectsScandinavia,
    categories,
    defaultDescription,
  } = mapToSingleObject(overview);

  const featuredProjects = useMemo(
    () =>
      normalizeProjects(
        region === 'SCAND' ? featuredProjectsScandinavia : featuredProjectsWorld
      ),
    [region, featuredProjectsWorld, featuredProjectsScandinavia]
  );

  const normalizedProjects = useMemo(
    () => normalizeProjects(mapEdgesToNodes(projects)),
    [projects]
  );

  // get category and view state from url params
  const { category = null, view = null } = getQueryParams(location.search);

  const filteredProjects = category
    ? shuffle(
        normalizedProjects.filter(project =>
          project.categoryIndex.includes(category)
        )
      )
    : featuredProjects;

  return (
    <PageContainer>
      <Head title={title} meta={meta} />

      <Heading h={1} $hidden>
        {title}
      </Heading>

      <ProjectOverviewCategories
        defaultDescription={defaultDescription}
        categories={categories}
        activeCategory={category}
        activeView={view}
      />

      {view === 'list' ? (
        <ProjectTable projects={filteredProjects} />
      ) : (
        <ProjectGrid
          projects={filteredProjects}
          showHighlighted={category === null}
        />
      )}

      <ModulesLoop contentModules={contentModules} />

      <Footer />
    </PageContainer>
  );
};

export const query = graphql`
  query($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      title
      meta {
        ...metaData
      }
      contentModules {
        ...contentModulesData
      }
    }

    projects: allSanityProject {
      edges {
        node {
          ...projectTileData
          categories {
            slug {
              current
            }
          }
        }
      }
    }

    overview: allSanityProjectOverviewSettings {
      edges {
        node {
          featuredProjectsWorld: projects {
            project {
              ...projectTileData
              categories {
                slug {
                  current
                }
              }
            }
            isHighlighted
            highlightedImageAlignment
          }
          featuredProjectsScandinavia: projectsScandinavia {
            project {
              ...projectTileData
              categories {
                slug {
                  current
                }
              }
            }
            isHighlighted
            highlightedImageAlignment
          }
          categories {
            title
            slug {
              current
            }
            description {
              textRaw: _rawText
            }
          }
          defaultDescription {
            textRaw: _rawText
          }
        }
      }
    }
  }
`;
