import React from 'react';

import Grid from '@/atoms/Grid';
import ProjectTile from '@/components/ProjectTile';

import theme from '@/styles/theme';

export default ({ projects, showHighlighted = true, ...props }) => {
  return (
    <Grid.Container mb={theme.spacing.large} {...props}>
      <Grid mb={[-4, -3.5]}>
        {projects.map(
          ({ key, isHighlighted, categoryIndex, ...projectData }) => {
            const columnWidth =
              showHighlighted && isHighlighted
                ? [1, 1 / 2, 1 / 2, 1]
                : [1, 1 / 2, 1 / 2, 1 / 3];
            return (
              <Grid.Item as="article" key={key} w={columnWidth} mb={[4, 3.5]}>
                <ProjectTile
                  isHighlighted={showHighlighted && isHighlighted}
                  columnWidth={columnWidth}
                  {...projectData}
                />
              </Grid.Item>
            );
          }
        )}
      </Grid>
    </Grid.Container>
  );
};
