import React from 'react';

import Descriptions from './Descriptions';
import Categories from './Categories';
import Views from './Views';
import Grid from '@/atoms/Grid';

import theme from '@/styles/theme';

export default ({
  defaultDescription,
  categories,
  activeCategory,
  activeView,
  ...props
}) => {
  return (
    <Grid.Container {...props}>
      <Grid>
        <Grid.Item w={[1, 10 / 12, 10 / 12, 7 / 12]} mb={theme.spacing.lhH2}>
          <Descriptions
            activeCategory={activeCategory}
            categories={categories}
            defaultDescription={defaultDescription}
          />
        </Grid.Item>

        <Grid.Item
          w={[8 / 12, 10 / 12, 10 / 12, 4 / 12]}
          o={[0, 0, 0, 1 / 12]}
          pt={[0, 0, 0, 1]}
          mb={6}
        >
          <Categories
            activeCategory={activeCategory}
            activeView={activeView}
            categories={categories}
          />
        </Grid.Item>

        <Grid.Item
          w={[4 / 12, 2 / 12, 2 / 12, 4 / 12]}
          o={[0, 0, 0, 8 / 12]}
          mb={[6, 6, 6, 3]}
        >
          <Views activeCategory={activeCategory} activeView={activeView} />
        </Grid.Item>
      </Grid>
    </Grid.Container>
  );
};
