import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import RichText from '@/atoms/RichText';
import useDebouncedResize from '@/hooks/useDebouncedResize';

const Holder = styled(Box)`
  position: relative;
`;

const Description = styled(RichText).attrs({ t: 'h2' })`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: opacity 0.35s ease-out;

  :first-child {
    position: static;
  }
`;

export default ({
  categories,
  activeCategory = null,
  defaultDescription,
  ...props
}) => {
  const [maxHeight, setMaxHeight] = useState('auto');
  const ref = useRef();

  const calcMaxHeight = useCallback(() => {
    let tempMaxHeight = 0;

    ref.current.childNodes.forEach(el => {
      const rect = el.getBoundingClientRect();

      if (rect.height > tempMaxHeight) {
        tempMaxHeight = rect.height;
      }
    });

    setMaxHeight(`${tempMaxHeight}px`);
  }, [ref, setMaxHeight]);

  useDebouncedResize(() => {
    calcMaxHeight();
  });

  useEffect(calcMaxHeight, [calcMaxHeight]);

  return (
    <Holder ref={ref} $height={maxHeight} {...props}>
      <Description
        key="default"
        isActive={activeCategory === null}
        blocksRaw={defaultDescription.textRaw}
      />

      {categories.map(({ slug, description }) => (
        <Description
          key={slug.current}
          isActive={slug.current === activeCategory}
          blocksRaw={
            description ? description.textRaw : defaultDescription.textRaw
          }
        />
      ))}
    </Holder>
  );
};
